<template>
  <!-- 我的考试 -->
  <div class="foxRight">
    <!-- 试卷包 -->
    <div class="list-two">
      <div v-for="(item, index) in list" :key="index" class="content" @click.stop="getDetail(item)">
        <div style="margin-bottom: 0px;margin-top: 10px;" class="item">
          <template>
            <img class="item-img" src="@/assets/img/homeSeventh/roomIcon.png" alt="" />
            <div class="item-content">
              <div class="item-text">{{ item.name }}</div>
              <div class="item-tips">
                <div class="tips-text">共{{ item.roomPaperSize }}套试卷</div>
                <span class="present">{{ item.zrNum }}人已参加</span>
              </div>
            </div>
            <div class="item_time">
              {{ item.startTime }}&nbsp;&nbsp;-&nbsp;&nbsp;{{ item.endTime }}
            </div>
            <img v-if="!item.open" style="height: 16px; width: 8px; margin-top: 3px" src="@/assets/img/partner/arrow.png"
              alt="" />
            <img v-else style="height: 8px; width: 16px; margin-top: 8px" src="@/assets/img/partner/down.png" alt="" />
          </template>
        </div>
        <div v-show="item.open" v-for="(items, indexs) in PaperList" :key="indexs" class="paperNameList">
          <div class="imgBox">
            <img src="@/assets/img/partner/paper2.png" alt="" />
          </div>
          <div class="paper_centent">
            <div class="item-text">{{ items.name }}</div>
            <div class="paperName-text">
              共{{ items.subjectNum ? items.subjectNum : 0 }}题
            </div>
          </div>
          <el-button class="btn" v-if="items.isWork == 'false' && item.roomStatus == 2"
            @click.stop="goLearnss(items, item)">开始答题</el-button>
          <el-button class="btn1" v-if="items.isWork != 'false'" @click.stop="goLearns(items, item)">查看成绩</el-button>
        </div>
      </div>
    </div>
    <div v-if="!list || list.length == 0" class="nothing">
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <el-pagination class="pagination colleges-pagination" :total="total" :current-page="pageNum"
      :page-sizes="[8, 16, 25, 40]" :page-size="pageSize" :pager-count="5" background
      layout="total, sizes, prev, pager, next" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
  </div>
</template>
<script>
import Vue from "vue";
import { getInfo } from "@/api/cookies";
import { AnswerClient } from "@/api/task/answerClient";
import { format, compareAsc, parseISO } from "date-fns";
const answerClient = new AnswerClient();
import { roomType } from "@/api/emun";
import { tr } from "date-fns/locale";
export default {
  components: {},

  data() {
    return {
      mTypeEnum: roomType,
      list: [], // 查询列表
      PaperList: "",
      getInfo: null,
      pageSize: 8,
      pageNum: 1,
      commitStatus: 0,
      btnInfo: 0,
      total: 0,
    };
  },
  async created() {
    this.userInfo = getInfo();
    this.getList();
  },
  methods: {
    /* 获取列表 */
    getList() {
      /* 虚拟考场 */
      answerClient
        .getPcMyRoom(
          this.pageSize,
          this.pageNum,
          undefined,
          undefined,
          this.userInfo ? this.userInfo.id : undefined
        )
        .then((res) => {
          res.rows.forEach((element) => {
            console.log(parseISO(element.endTime));
            if (compareAsc(parseISO(element.endTime), new Date())) {
              element.gray = true;
            }
          });
          this.list = res.rows;
          console.log("this.list===============", this.list);
          this.total = res.total;
        });
    },
    async getDetail(val) {
      this.list.forEach((item) => {
        if (item.id == val.id) {
          val.open = !val.open;
        } else {
          item.open = false;
        }
      });
      const res = await answerClient.getPcRoomDetails(val.id, this.userInfo.id);
      if (res.code === 0) {
        this.PaperList = res.data.PaperList;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    goRoom(item) {
      const sub = {
        roomId: item.id,
        classId: item.classId,
      };
      const data = Vue.prototype.export(sub);
      this.$router.push(`/quesTion/examRoom/examRoomDetail?${data}`);
    },
    goLearnss(items, item) {
      this.$router.replace({
        path: "/quesTion/examDetails",
        query: {
          title: items.name,
          paperId: items.id,
          examType: 3,
          type: 6,
          courseId: items.id,
          classId: item.classId,

          IsPurchase: "true",
          roomId: item.id,
        },
      });
    },
    goLearns(items, item) {
      this.$router.replace({
        path: "/quesTion/examDetails",
        query: {
          title: items.name,
          paperId: items.id,
          examType: 3,
          type: 6,
          courseId: items.id,
          classId: item.classId,
          isSubmited: true,
          IsPurchase: "true",
          roomId: item.id,
        },
      });
    },
    gradesView(items, item) {
      this.$router.push({
        path: "/partnerPage/report",
        query: {
          title: items.name,
          paperId: items.id,
          examType: 3,
          roomId: item.id,
        },
      });
    },
    changeTabs(index) {
      this.btnInfo = index;
      const status = index === 0 ? 2 : index === 2 ? 0 : 1;
      this.commitStatus = status;
      this.getList(status);
    },
    expired(item) {
      console.log(item);
      if (item.roomStatus == 1) {
        this.$message({
          type: "warning",
          message: "考场未开始",
        });
      } else if (item.roomStatus == 3) {
        this.$message({
          type: "warning",
          message: "考场已结束",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
/* 试卷列表 */
.list-two {
  background: #fff;
  margin-top: 15px;
  padding: 0;

  .paperNameList {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 77px;
    margin-left: 70px;
    width: 934px;
    border-bottom: 1px dashed #eee;

    .imgBox {
      width: 33px;
      height: 36px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .paper_centent {
      margin-left: 12px;

      .item-text {
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }

      .paperName-text {
        font-size: 10px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-top: 5px;
      }
    }

    .btn {
      position: absolute;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 108px;
      height: 36px;
      border-radius: 25px 25px 25px 25px;
      opacity: 1;
      border: 1px solid #2586f5;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #2586f5;

      &:hover {
        border: 0;
      }
    }

    .examine {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #717680;
      position: absolute;
      right: 157px;
    }

    .btn1 {
      width: 108px;
      height: 36px;
      border-radius: 18px 18px 18px 18px;
      opacity: 1;
      border: 1px solid #717680;
      position: absolute;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #717680;
    }

    .btn:hover {
      width: 108px;
      height: 36px;
      background: linear-gradient(360deg, #0270e8 0%, #21b4f5 100%);
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .paperNameList:nth-last-child(1) {
    border: none;
  }

  .item {
    cursor: pointer;
    width: 1040px;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    padding: 19px;
    // margin-bottom: 20px;

    .item-img {
      width: 38.6px;
      height: 39px;
      margin-right: 8px;
    }

    .item-content {
      width: 65%;
      height: auto;
      margin-top: 10px;

      .item-text {
        height: auto;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 0;
      }

      .item-tips {
        margin-top: 12px;
        display: flex;
        align-items: center;

        .tips-text {
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }

        .present {
          width: 70px;
          height: 16px;
          background: #fceee6;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #fe6e0e;
          padding: 1px 6px;
          text-align: center;
          margin-left: 8px;
        }
      }
    }

    .item_time {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-right: 17px;
      flex-shrink: 0;
    }
  }
}
</style>
